<template>
  <div class="login-overlay">
    <div class="login-window" id="login-window">
      <div class="login-window-content" id="login-window-content">
        <div class="logo">
          <img class="sidebar-logo" alt="Amicaldo logo" src="../assets/amicaldo_wortmarke_weiß.svg">
        </div>
        <h2>Anmelden</h2>
        <div class="input-group">
          <div class="input-icon">
            <img class="icon" alt="Amicaldo logo" src="../assets/user-solid.svg">
          </div>
          <div class="input-field">
            <input type="email" placeholder="Email eingeben" id="user-email" />
          </div>
        </div>
        <div class="input-group">
          <div class="input-icon">
            <img class="icon" alt="Amicaldo logo" src="../assets/key-solid.svg">
          </div>
          <div class="input-field">
            <input type="password" placeholder="Passwort eingeben" id="user-password" />
          </div>
        </div>

        <button v-on:click="tryLogin">Anmelden</button>
      </div>

      <div class="login-successfully" id="login-successfully">
        <img src="../assets/checkmark.svg"  alt="successful" class="login-successful-icon"/>
        <div class="login-successful-text">
          Erfolgreich eingeloggt!
        </div>
      </div>

      <div class="login-successfully" id="login-failed">
        <img src="../assets/error.svg"  alt="successful" class="login-successful-icon"/>
        <div class="login-successful-text">
          Nutzername oder Passwort ist falsch!
        </div>
      </div>

      <div class="login-successfully" id="login-unknown">
        <img src="../assets/unknown.svg"  alt="successful" class="login-successful-icon"/>
        <div class="login-successful-text">
          Ein Unbekannter Fehler ist aufgetreten!
        </div>
      </div>
      <!--<button v-on:click="startGetCredentials">Mit WebAuthn anmelden</button>-->
    </div>

    <div class="login-background"></div>
  </div>
</template>

<script>

import router from "@/router";

export default {
  name: 'Shipped',
  mounted() {
    document.addEventListener('keypress', (e) => {
      if (e.code === 'Enter') {
        this.tryLogin();
      }
    });

    if(localStorage.getItem("token")) {
      this.axios.get("/api/auth/isAuthenticated", {
        headers: {
          token: localStorage.getItem("token")
        }
      })
      .then(() => {
        router.push({ name: 'Status' });
      })
      .catch(() => {
        localStorage.removeItem("token");
      })
    }
  },
  methods: {
    async startGetCredentials() {
      if(localStorage.getItem("webauthn_key_id")) {
        let options = {
          challenge: new Uint8Array([7,3,8,4,1,6,9,0]),
          rpId: "localhost",
          allowCredentials: [
            {
              id: Buffer.from(localStorage.getItem("webauthn_key_id_raw"), 'hex'),
              type: "public-key"
            }
          ],
          userVerification: "preferred",
          timeout: 60000
        };

        const assertion = await navigator.credentials.get({
          publicKey: options
        });

        console.log(assertion);
      }
    },
    switchToDashboard() {
      document.getElementById("login-window").style.backgroundColor = "#BADA55";
      document.getElementById("login-window-content").style.opacity = "0";

      setTimeout(() => {
        document.getElementById("login-window-content").style.display = "none";
        document.getElementById("login-successfully").style.display = "flex";
        document.getElementById("login-successfully").style.opacity = "1";
      },501);

      setTimeout(() => {
        router.push({ name: 'Status', params: { login: 'true' } });
      },1500);
    },
    tryLogin() {
      let email = document.getElementById("user-email").value;
      let password = document.getElementById("user-password").value;

      this.axios.post("/api/auth/login", {
        email: email,
        password: password
      })
          .then((res) => {
            console.log(res);
            localStorage.setItem("token", res?.data?.token);
            this.switchToDashboard();
          })
          .catch((err) => {
            console.log(err);
            if(err?.response?.status === 401) {
              this.displayLoginError();
            } else {
              this.displayUnknownError();
            }
          })
    },
    displayLoginError() {
      document.getElementById("login-window").style.backgroundColor = "#eb4034";
      document.getElementById("login-window-content").style.opacity = "0";

      setTimeout(() => {
        document.getElementById("login-window-content").style.display = "none";
        document.getElementById("login-failed").style.display = "flex";
        document.getElementById("login-failed").style.opacity = "1";
      },500);

      setTimeout(() => {
        document.getElementById("login-failed").style.display = "none";
        document.getElementById("login-failed").style.opacity = "0";
      },1500);

      setTimeout(() => {
        document.getElementById("login-window-content").style.display = "unset";
        document.getElementById("login-window-content").style.opacity = "1";
        document.getElementById("login-window").style.backgroundColor = "#393d42";
      }, 2000);
    },
    displayUnknownError() {
      document.getElementById("login-window").style.backgroundColor = "#eb9c34";
      document.getElementById("login-window-content").style.opacity = "0";

      setTimeout(() => {
        document.getElementById("login-window-content").style.display = "none";
        document.getElementById("login-unknown").style.display = "flex";
        document.getElementById("login-unknown").style.opacity = "1";
      },500);

      setTimeout(() => {
        document.getElementById("login-unknown").style.display = "none";
        document.getElementById("login-unknown").style.opacity = "0";
      },1500);

      setTimeout(() => {
        document.getElementById("login-window-content").style.display = "unset";
        document.getElementById("login-window-content").style.opacity = "1";
        document.getElementById("login-window").style.backgroundColor = "#393d42";
      }, 2000);
    },
    mounted() {
      if(localStorage.getItem("amc_use_localhost")) {
        this.baseUrl = `http://localhost:${localStorage.getItem("amc_use_localhost")}`;
      } else {
        this.baseUrl = "/api";
      }
    }
  }
}
</script>

<style scoped>
.login-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
}

.login-successfully {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 5s;
}

.login-successful-icon {
  width: 8vw;
  height: 8vw;
}

.login-successful-text {
  color: white;
  font-size: 30px;
}

h2 {
  color: white;
}

.icon {
  filter: invert(100%);
  height: 1.7vw;
}

.login-window {
  width: 35vw;
  height: 100vh;
  background-color: #393d42;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: background-color 0.2s ease-in-out, margin-left 1s 0.7s ease-in-out;
  margin-left: 0;
  transform: translateZ(0);
}
.login-window-content {
  transition: opacity 0.5s;
}

.login-background {
  width: 65vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 20;
  background-image: url("../assets/Sprinkle.svg");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
}

.image-wrapper {
  position: relative;
  z-index: 10;
}

.login-background-image {
  width: 65vw;
  height: 100vh;
  object-fit: cover;
  background-position: top left;
  filter: blur(0px);
}

.background-text {
  width: 65vw;
  height: 100vh;
  position: relative;
  z-index: 15;
  margin-top: -100vh;
}

form {
  width: 30vw;
}

.input-group {
  width: 30vw;
  height: 4vh;
  display: flex;
  margin-top: 2vh;
}

.input-icon {
  width: 3vw;
  height: 3vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-field {
  width: 27vw;
  height: 3vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

input, button {
  width: 25vw;
  background-color: transparent;
  border: none;
  color: grey;
  height: 4vh;
  padding: 0;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
}

.logo {
  width: 20vw;
  height: 10vh;
  display: flex;
  align-items: center;
}
</style>
