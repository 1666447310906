<template>
  <div class="table">
    <div class="table-utils">
      <div class="search-data">
        <input type="search" class="search-input" v-model="search" v-on:input="searchData" placeholder="Suchen...">
      </div>
      <div class="select-page">
        <div class="input-wrapper">
          <input class="page-input" type="number" v-on:change="changePage" v-model="page" />
        </div>
        von {{ maxPage }} Seiten
      </div>
    </div>
    <table class="table-data" cellspacing="0" cellpadding="0" v-if="data.length > 0">
      <tr class="table-head">
        <td class="head-column" :key="index" v-for="(item, index) in Object.keys(data[0] ?? {})">{{ mapTranslations(item) }}</td>
      </tr>
      <tr :key="index2" v-for="(item, index2) in data" v-on:dblclick="redirect(item)">
        <td :key="key" v-for="(value, key) of item"> {{ mapTranslations(value) ?? "N/A" }}</td>
      </tr>
    </table>
    <div class="nothing-found" v-if="data.length === 0">Es wurden keine Einträge gefunden</div>
  </div>
</template>

<script>
export default {
  name: 'Table',
  props: ['endpoint'],
  data() {
    return {
      data: [],
      page: 1,
      maxPage: 8,
      search: ""
    }
  },
  methods: {
    mapTranslations(key) {
      let translations = {
        orderId: "ID",
        name: "Nachname",
        firstname: "Vorname",
        orderNumber: "Auftragsnummer",
        completed: "Status",
        true: "Abgeschlossen",
        false: "Ausstehend"
      }

      return translations[key] ?? key;
    },
    changePage() {
      if(this.page < 1) {
        this.page = 1;
      } else if(this.page > this.maxPage) {
        this.page = this.maxPage;
      }

      if(this.search.length > 0) {
        this.searchData();
      } else {
        this.axios.get("/api" + this.endpoint + "?page=" + (this.page - 1)).then((res) => {
          console.log(res);
          this.data = res.data;
        })
      }
    },
    searchData() {
      if(this.page < 1) {
        this.page = 1;
      } else if(this.page > this.maxPage) {
        this.page = this.maxPage;
      }

      this.axios.get("/api" + this.endpoint + "/searchCount?page=" + (this.page - 1) + "&q=" + this.search).then((res) => {
        this.maxPage = Math.ceil(res.data.count / 50);
      })

      this.axios.get("/api" + this.endpoint + "/search?page=" + (this.page - 1) + "&q=" + this.search).then((res) => {
        this.data = res.data;
      })
    },
    redirect(item) {
      console.log(item);
      this.$router.push({ name: 'Details', params: { id: item.orderId } });
    }
  },
  mounted() {
    if(localStorage.getItem("amc_use_localhost")) {
      this.baseUrl = `http://localhost:${localStorage.getItem("amc_use_localhost")}`;
    } else {
      this.baseUrl = "/api";
    }

    this.axios.get("/api" + this.endpoint + "/count?page=" + (this.page - 1)).then((res) => {
      console.log(res);
      this.maxPage = Math.ceil(res.data.count / 50);
    })

    this.axios.get("/api" + this.endpoint + "?page=" + (this.page - 1)).then((res) => {
      this.data = res.data;
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table {
  width: 74vw;
  margin-bottom: 5vh;
}

.select-page {
  color: white;
  display: flex;
  align-items: center;
}

.table-utils {
  width: 74vw;
  height: 6vh;
  background-color: #393d42;
  margin-bottom: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.nothing-found {
  background-color: #393d42;
  width: 74vw;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 20px
}

.table-utils > *:first-child {
  margin-left: 50px!important;
}

.table-utils *:not(:first-child) {
  margin-left: 20px;
}

.table-data {
  width: 74vw;
}

.table-head {
  width: 74vw;
  height: 5vh;
  color: white;
}

.page-input {
  width: 40px;
  height: 30px;
  margin-right: 10px;
  border: none;
  border-radius: 10px;
  background-color: #484e54;
  color: white;
  text-align: center;
  -moz-appearance: textfield;
}

.page-input::-webkit-outer-spin-button,
.page-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.search-input {
  width: 150px;
  height: 30px;
  border: none;
  border-radius: 10px;
  background-color: #484e54;
  color: white;
  padding-left: 10px;
}

td {
  color: white;
  padding: 20px 20px 20px 50px;
}

tr:not(:last-of-type) {
  border-bottom: 1px solid #484e54;
}

tr:not(:first-of-type) {
  cursor: pointer;
}

table {
  background-color: #393d42;
  border-radius: 20px;
  border-collapse: collapse;
}

.head-column {
  font-weight: bold;
}
</style>

<style>
  .padding {
    height: unset!important;
  }
</style>
