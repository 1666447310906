<template>
  <div class="padding">
    <h1>Retouren</h1>
    <Table endpoint="/returns"></Table>
  </div>
</template>

<script>
import Table from "@/components/Table";

export default {
  name: 'Returns',
  components: {Table}
}
</script>

<style scoped>
h1 {
  color: white;
}

.padding {
  width: 74vw;
  height: calc(100vh - 6vw);
}
</style>
