<template>
  <div class="login-overlay" id="login-overlay">
    <div class="login-window" id="login-window">
      <div class="login-successfully">
        <img src="../assets/checkmark.svg"  alt="successful" class="login-successful-icon"/>
        <div class="login-successful-text">
          Erfolgreich eingeloggt!
        </div>
      </div>
    </div>

    <div class="login-background" id="login-background">
    </div>
  </div>
</template>

<script>
import Velocity from 'velocity-animate';

export default {
  name: 'LoginOverlay',
  mounted() {
    setTimeout(() => {
      Velocity(document.getElementById("login-window"), {
        translateX: "100vw"
      })
      Velocity(document.getElementById("login-background"), {
        width: "0"
      })
      Velocity(document.getElementById("login-overlay"), {
        width: "0"
      })
    }, 1000)
  }
}
</script>

<style scoped>
.login-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
}

h2 {
  color: white;
}

.login-successfully {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-successful-icon {
  width: 8vw;
  height: 8vw;
}

.login-successful-text {
  color: white;
  font-size: 30px;
}

.login-window {
  width: 35vw;
  height: 100vh;
  background-color: #BADA55;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: background-color 0.2s ease-in-out, margin-left 1s 0.7s ease-in-out;
  margin-left: 0;
  transform: translateZ(0);
  z-index: 30;
}

.login-background {
  width: 65vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 20;
  background-image: url("../assets/Sprinkle.svg");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
}

.image-wrapper {
  position: relative;
  z-index: 10;
}

.login-background-image {
  width: 65vw;
  height: 100vh;
  object-fit: cover;
  background-position: top left;
  filter: blur(0px);
}
</style>
