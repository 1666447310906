<template>
  <div class="padding">
    <h1>Status</h1>
    <div class="content-group">
      <div class="headline-group">
        <h2>Statistiken und Zahlen</h2>
        <p>Eine Übersicht der bisherigen erfolgten Aktionen der Schnittstelle.</p>
        <div class="numbers-column">
          <div class="numbers-entity">
            <div class="number green" :class="currentlyRunningColor" v-html="currentlyRunning"></div>
            <div class="description">
              Schnittstelle
            </div>
          </div>

          <div class="numbers-entity">
            <div class="number" v-html="parseInt(registeredCount) + parseInt(sendToZfCount) + parseInt(shippedCount)"></div>
            <div class="description">
              Bestellungen verarbeitet
            </div>
          </div>

          <div class="numbers-entity">
            <div class="number" v-html="parseInt(registeredCount)"></div>
            <div class="description">
              Bestellungen weitergeleitet
            </div>
          </div>

          <div class="numbers-entity">
            <div class="number" v-html="parseInt(shippedCount)"></div>
            <div class="description">
              Bestellungen versendet
            </div>
          </div>

          <div class="numbers-entity">
            <div class="number" v-html="parseInt(returnedCount)"></div>
            <div class="description">
              Retouren <br> erstellt
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--
    <div class="content-group">
      <div class="headline-group">
        <h2>Aktueller Zyklus</h2>
        <p>Metadaten zum aktuellen Zyklusdurchlauf.</p>
      </div>

      <div class="numbers-column">
        <div class="numbers-entity centered">
          <div class="description">
            Aktuell ist kein Durchlauf aktiv
          </div>
        </div>
      </div>
    </div>
    -->

    <div class="content-group">
      <div class="headline-group">
        <h2>Aktuelle Worker</h2>
        <p>Informationen zum aktuell gestarteten Worker Prozess.</p>
      </div>
      <div class="numbers-column">
        <div class="numbers-entity centered">
          <div class="description">
            Aktuell laufen keine Worker Prozesse
          </div>
        </div>
      </div>
    </div>
  </div>

  <LoginOverlay v-if="$route?.params?.login ?? false"></LoginOverlay>
</template>

<script>
// @ is an alias to /src
import LoginOverlay from "@/components/LoginOverlay";

export default {
  name: 'Status',
  components: {LoginOverlay},
  data() {
    return {
      currentlyRunning: false,
      currentlyRunningColor: "",
      registeredCount: 0,
      shippedCount: 0,
      returnedCount: 0,
      sendToZfCount: 0
    }
  },
  mounted() {
    this.refreshStatistics();
    if(localStorage.getItem("amc_use_localhost")) {
      this.baseUrl = `http://localhost:${localStorage.getItem("amc_use_localhost")}`;
      console.log(this.baseUrl);
    } else {
      this.baseUrl = "/api";
    }
  },
  methods: {
    async refreshStatistics() {
      let statistics = await this.axios.get("/api/statistics");

      this.currentlyRunning = this.mapCurrentlyRunning(statistics?.data?.currently_running, statistics?.data?.currently_hard_stopped);
      this.currentlyRunningColor = this.mapCurrentlyRunningColor(statistics?.data?.currently_running, statistics?.data?.currently_hard_stopped);
      this.registeredCount = statistics?.data?.registered_count;
      this.shippedCount = statistics?.data?.shipped_count;
      this.sendToZfCount = statistics?.data?.send_to_zf_count;
      this.returnedCount = statistics?.data?.returned_count;

      setTimeout(this.refreshStatistics, 2 * 1000);
    },
    mapCurrentlyRunning(cr, chs) {
      if(chs === "true") {
        return "Inaktiv";
      } else {
        if(cr === "true") {
          return "Aktiv";
        } else {
          return "Idle";
        }
      }
    },
    mapCurrentlyRunningColor(cr, chs) {
      if(chs === "true") {
        return "red";
      } else {
        if(cr === "true") {
          return "green";
        } else {
          return "yellow";
        }
      }
    }
  }
}
</script>

<style scoped>
h1 {
  color: white;
}

.padding {
  width: 74vw;
  height: calc(100vh - 6vw);
}

.numbers-column {
  width: 74vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.numbers-entity {
  width: 8vw;
  height: 6vw;
  background-color: #2C2F33;
  border-radius: 10px;
  padding-top: 1vh;
  padding-bottom: 1vh;
}

.numbers-entity:not(:first-of-type) {
  margin-left: 1vw;
}

.number {
  font-size: 3vh;
  width: 8vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.description {
  width: 7vw;
  padding: 0.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.3vh;
  color: #707882;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.green {
  color: #bada55;
}

.yellow {
  color: yellow;
}

.red {
  color: #e6374e;
}
</style>
