<template>
  <div class="main-content">
    <router-view/>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: 'MainContent',
  mounted() {
    this.axios.interceptors.response.use((config) => {
      return config;
    }, (config) => {
      if(config?.response?.status === 401) {
        localStorage.removeItem("token");
        router.push({ name: 'Login' });
        return Promise.reject(config);
      }
      return config;
    })

    this.axios.interceptors.request.use((config) => {
      if(localStorage.getItem("token")) {
        config.headers.token = localStorage.getItem("token");
      }
      return config;
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-content {
  width: 80vw;
  height: 100vh;
  background-color: #2C2F33;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}
</style>
