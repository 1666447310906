<template>
  <div class="padding">
    <h1>Aktionen</h1>

    <div class="content-group">
      <div class="headline-group">
        <h2>Schnittstelle neustarten</h2>
        <p>Startet die Schnittstelle neu.</p>
        <button v-on:click="restartSync" class="red">Neustarten</button>
      </div>
    </div>

    <div class="content-group">
      <div class="headline-group">
        <h2>Schnittstelle Aktivieren/Deaktivieren</h2>
        <p>Metadaten zum aktuellen Zyklusdurchlauf.</p>
        <div class="button-container">
          <button v-on:click="stopSync" class="red">Deaktivieren</button>
          <button v-on:click="startSync" class="green">Aktivieren</button>
        </div>
      </div>
    </div>

    <div class="content-group">
      <div class="headline-group">
        <h2>Worker Starten</h2>
        <p>Startet einen neuen Prozess, welcher die Synchronisierung einer einzelnen Synchronisierungsrichtung einmalig bearbeitet. Achtung: pausiert den aktuellen Zyklusdurchlauf</p>
        <div class="button-container">
          <button v-on:click="startWcZfWorker">Weclapp Worker Starten</button>
          <button v-on:click="startZfWcWorker">Zenfulfillment Lieferungs Worker Starten</button>
          <button v-on:click="startReturnsWorker">Zenfulfillment Retouren Worker Starten</button>
        </div>
      </div>
    </div>

    <div class="content-group">
      <div class="headline-group">
        <h2>Ausloggen</h2>
        <p>Loggt den aktuellen Nutzer aus und kehrt zum Login-Screen zurück.</p>
        <button v-on:click="logout" class="red">Ausloggen</button>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: 'Actions',
  methods: {
    logout() {
      localStorage.removeItem("token");
      router.push({ name: 'Login', params: { logout: 'true' } });
    },
    startZfWcWorker() {
      this.axios.post("/api/processManagement/startZfWcWorker");
    },
    startWcZfWorker() {
      this.axios.post("/api/processManagement/startWcZfWorker");
    },
    startReturnsWorker() {
      this.axios.post("/api/processManagement/startReturnsWorker");
    },
    startSync() {
      this.axios.post( "/api/processManagement/startCycle")
      .then(() => {
        console.log("erfolgreich gestartet");
      });
    },
    stopSync() {
      this.axios.post("/api/processManagement/stopCycle")
          .then(() => {
            console.log("erfolgreich gestoppt");
          });
    },
    restartSync() {
      this.axios.post("/api/processManagement/restartCycle")
          .then(() => {
            console.log("erfolgreich neugestartet");
          });
    },
    mounted() {
      if(localStorage.getItem("amc_use_localhost")) {
        this.baseUrl = `http://localhost:${localStorage.getItem("amc_use_localhost")}`;
      } else {
        this.baseUrl = "/api";
      }
    }
  }
}
</script>

<style scoped>
h1 {
  color: white;
}

.padding {
  width: 74vw;
  height: calc(100vh - 6vw);
}

.button-container {
  display: flex;
  flex-direction: column;
}

button {
  width: 400px;
  height: 40px;
  background-color: transparent;
  color: #707882;
  border: 2px solid #707882;
  margin-top: 10px;
  cursor: pointer;
}

button:hover {
  color: white;
  background-color: #707882;
}

button.red {
  border-color: red;
  color: red;
}

button.red:hover {
  border-color: red;
  color: white;
  background-color: red;
}

button.green {
  border-color: #bada55;
  color: #bada55;
}

button.green:hover {
  border-color: #bada55;
  color: white;
  background-color: #bada55;
}
</style>
