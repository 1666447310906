<template>
  <div class="padding">
    <div class="succsess-banner" id="success"></div>
    <h1>Einstellungen</h1>
    <!--
    <div class="content-group">
      <div class="headline-group">
        <h2>Allgemeine Einstellungen</h2>
        <p>Metadaten zum aktuellen Zyklusdurchlauf.</p>
      </div>

      <div class="checkbox-group">
        <div class="switch">
          <input type="checkbox" id="auto-restart-cycle" v-model="autoRestartCycle"/><label for="auto-restart-cycle">Toggle</label>
        </div>
        <div class="switch-label">Automatischer Neustart von Prozessen nach kritischen Fehlern</div>
      </div>
      <div class="checkbox-group">
        <div class="switch">
          <input type="checkbox" id="show-order-details" v-model="showOrderDetails"/><label for="show-order-details">Toggle</label>
        </div>
        <div class="switch-label">Rufe sensible Details zu ausgewählten Bestellungen ab und zeige diese in einer Übersicht an. (z.B Kundenadresse, Bestellstatus, etc...)</div>
      </div>
    </div>
    -->

    <div class="content-group">
      <div class="headline-group">
        <h2>Zyklus Einstellungen</h2>
        <p>Einstellungen, die den Kontinuierlichen Zyklus beeinflussen</p>
      </div>
      <div class="checkbox-group">
        <div class="switch">
          <input type="checkbox" id="weclapp-zenfulfillment-sync" v-model="weclappZenfulfillmentSync"/><label for="weclapp-zenfulfillment-sync">Toggle</label>
        </div>
        <div class="switch-label">Lieferungen von Weclapp zu Zenfulfillment übertragen</div>
      </div>
      <div class="checkbox-group">
        <div class="switch">
          <input type="checkbox" id="zenfulfillment-weclapp-sync" v-model="zenfulfillmentWeclappSync"/><label for="zenfulfillment-weclapp-sync">Toggle</label>
        </div>
        <div class="switch-label">Versand-Updates von Zenfulfillment zu Weclapp übertragen</div>
      </div>
      <div class="checkbox-group">
        <div class="switch">
          <input type="checkbox" id="zenfulfillment-return-sync" v-model="zenfulfillmentReturnSync"/><label for="zenfulfillment-return-sync">Toggle</label>
        </div>
        <div class="switch-label">Retouren von Zenfulfillment zu Weclapp übertragen</div>
      </div>
    </div>

    <div class="content-group">
      <div class="headline-group">
        <h2>Zenfulfillment Api</h2>
        <p>Der von Zenfulfillment erhaltene Api-Key</p>
      </div>
      <input id="zenfulfillment-api-key" v-model="zenfulfillmentApiKey" type="text" placeholder="Api Key.."/><br><br>
      <input id="zenfulfillment-tenant" v-model="zenfulfillmentTenant" type="text" placeholder="Zenfulfillment Subdomain..."/>
    </div>

    <div class="content-group margin-bottom">
      <div class="headline-group">
        <h2>Weclapp Api</h2>
        <p>Die im Weclapp generierten Api-Keys. Mindestens 1 Key ist erforderlich. Für manche Aktionen sind 2 oder mehr Api-Keys von Vorteil.</p>
      </div>
      <!--
      <button class="addApiKeyButton" v-on:click="addWeclappKeyInputField">+ ApiKey</button><br>
      -->
      <input id="weclapp-api-key" v-model="weclappApiKey" type="text" placeholder="Api Key.."/><br><br>
      <input id="weclapp-tenant" v-model="weclappTenant" type="text" placeholder="Weclapp Subdomain..."/>
      <!--
      <div id="weclapp-api-key">
        <input v-for="item in weclappApiKeys" :key="item.key" v-bind:id="item.key" type="text" placeholder="Api Key.." v-bind:value="item.value"/>
      </div>
      -->
    </div>

    <!--
    <div class="content-group margin-bottom">
      <div class="headline-group">
        <h2>WebAuthn</h2>
        <p>Bietet die möglichkeit sich per Fingerabdruck/Gesichtserkennung anzumelden</p>
      </div>
      <button class="addApiKeyButton" v-on:click="addWebAuthnCredentials">+ Client Hinzufügen</button><br>
      <div id="webauthn-keys">

      </div>
    </div>
    -->
  </div>
  <div class="settings-utils">
    <div class="cancel-button button" v-on:click="restoreSettings()">Abbrechen</div>
    <div class="save-button button" v-on:click="saveSettings()">Speichern</div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Settings',
  data() {
    return {
      autoRestartCycle: false,
      showOrderDetails: false,
      weclappZenfulfillmentSync: false,
      zenfulfillmentWeclappSync: false,
      zenfulfillmentReturnSync: false,
      weclappApiKey: "",
      zenfulfillmentApiKey: "",
      weclappTenant: "",
      zenfulfillmentTenant: ""
    }
  },
  mounted() {
    this.axios.get("/api/settings").then((response) => {
      for(let attribute in response.data) {
        switch(attribute) {
          case "auto-restart-cycle":
            this.autoRestartCycle = response.data[attribute] === "true";
            break;
          case "show-order-details":
            this.showOrderDetails = response.data[attribute] === "true";
            break;
          case "weclapp-zenfulfillment-sync":
            this.weclappZenfulfillmentSync = response.data[attribute] === "true";
            break;
          case "zenfulfillment-weclapp-sync":
            this.zenfulfillmentWeclappSync = response.data[attribute] === "true";
            break;
          case "zenfulfillment-return-sync":
            this.zenfulfillmentReturnSync = response.data[attribute] === "true";
            break;
          case "zenfulfillment-api-key":
            this.zenfulfillmentApiKey = response.data[attribute];
            break;
          case "weclapp-api-key":
            this.weclappApiKey = response.data[attribute];
            break;
          case "zenfulfillment-tenant":
            this.zenfulfillmentTenant = response.data[attribute];
            break;
          case "weclapp-tenant":
            this.weclappTenant = response.data[attribute];
            break;
        }
      }
    })
  },
  methods: {
    saveSettings() {
      let payload = {
        "auto-restart-cycle": this.autoRestartCycle.toString(),
        "show-order-details": this.showOrderDetails.toString(),
        "weclapp-zenfulfillment-sync": this.weclappZenfulfillmentSync.toString(),
        "zenfulfillment-weclapp-sync": this.zenfulfillmentWeclappSync.toString(),
        "zenfulfillment-return-sync": this.zenfulfillmentReturnSync.toString(),
        "weclapp-api-key": this.weclappApiKey.toString(),
        "zenfulfillment-api-key": this.zenfulfillmentApiKey.toString(),
        "weclapp-tenant": this.weclappTenant.toString(),
        "zenfulfillment-tenant": this.zenfulfillmentTenant.toString()
      }

      this.axios.post("/api/settings", payload).then(() => {
        this.showSuccessBanner("Die Einstellungen wurden erfolgreich gespeichert!", 5);
      })

      //location.reload();
    },
    restoreSettings() {
      document.getElementById("weclapp-api-key").innerHTML = "";

      this.axios.get("/api/settings").then((response) => {
        for(let attribute in response.data) {
          console.log(attribute);
          if(document.getElementById(attribute).type === "checkbox") {
            document.getElementById(attribute).checked = response.data[attribute] === "true";
          }
        }
      })
    },
    showSuccessBanner(message, duration) {
      document.getElementById("success").innerHTML = message;
      document.getElementById("success").style.height = "4vh";
      document.getElementById("success").style.opacity = "1";

      setTimeout(() => {
        document.getElementById("success").innerHTML = "";
        document.getElementById("success").style.opacity = "0";
        document.getElementById("success").style.height = "0";
      }, duration * 1000);
    },
    async addWebAuthnCredentials() {
      const publicKeyCredentialCreationOptions = {
        challenge: Uint8Array.from([1,5,7,8,2,46,9,2]),
        rp: {
          name: "Duo Security",
          id: "localhost",
        },
        user: {
          id: Uint8Array.from([1,5,7,8,2,46,9,2]),
          name: "steinert@amicaldo.de",
          displayName: "Leon Steinert",
        },
        pubKeyCredParams: [{alg: -7, type: "public-key"}],
        timeout: 60000,
        attestation: "direct"
      };

      let credentials = await navigator.credentials.create({
        publicKey: publicKeyCredentialCreationOptions
      });

      await this.axios.post("/api/auth/addWebAuthnPublicKey", {
        id: Buffer.from(credentials.rawId).toString('hex'),
        publicKey: Buffer.from(credentials.response.attestationObject).toString("hex"),
        clientData: Buffer.from(credentials.response.clientDataJSON).toString("hex")
      });

      localStorage.setItem("webauthn_key_id", credentials.id);
      localStorage.setItem("webauthn_key_id_raw", Buffer.from(credentials.rawId).toString('hex'));

      console.log(Buffer.from(credentials.rawId));
      console.log(Buffer.from(localStorage.getItem("webauthn_key_id_raw"), 'hex').compare(Buffer.from(credentials.rawId)));
    }
  }
}
</script>

<style>
.main-content {
  align-items: flex-start!important;
}

#weclapp-api-key input {
  margin-top: 1vh;
}
</style>

<style scoped>

h1 {
  color: white;
}

.padding {
  width: 74vw;
  margin-top: 2vw;
}

.checkbox-group {
  display: flex;
}

.switch {
  display: flex;
  align-items: center;
}

.switch-label {
  width: calc(73vw - 60px);
  height: 30px;
  color: white;
  padding-left: 1vw;
  display: flex;
  align-items: center;
}

input[type=checkbox]{
  height: 0;
  width: 0;
  visibility: hidden;
}

label {
  cursor: pointer;
  text-indent: -9999px;
  width: 40px;
  height: 20px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

label:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

input:checked + label {
  background: #bada55;
}

input:checked + label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

label:active:after {
  width: 22px;
}

.settings-utils {
  width: 78vw;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 6vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2vw;
}

.save-button {
  padding: 0.5vw 1vw 0.5vw 1vw;
  background-color: #bada55;
  border-radius: 10px;
  cursor: pointer;
}

.cancel-button {
  color: #23272A;
  padding: 0.5vw 1vw 0.5vw 1vw;
  background-color: #51575e;
  border-radius: 10px;
  margin-right: 1vw;
  cursor: pointer;
}

.button:hover {
  color: white;
}

.margin-bottom {
  margin-bottom: 8vh;
}

.addApiKeyButton {
  padding: 1vw;
  border: none;
  background-color: #bada55;
  border-radius: 10px;
}
</style>
