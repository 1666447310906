<template>
  <Sidebar></Sidebar>
  <MainContent></MainContent>
</template>

<style>
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: flex;
}

.content-group {
  width: 74vw;
  background-color: #393d42;
  border-radius: 10px;
  color: white;
  padding-left: 1vw;
  padding-top: 0.1vw;
  padding-bottom: 1vw;
  margin-top: 3vh;
}

.content-group input {
  width: 60vw;
  height: 3vh;
  border: none;
  background-color: #51575e;
  border-radius: 10px;
  padding-left: 1vw;
  color: white;
}

.headline-group p {
  margin-top: -1.5vh;
  color: #707882;
}

.succsess-banner {
  width: 74vw;
  height: 0;
  border-radius: 10px;
  opacity: 0;
  color: black;
  padding-left: 1vw;
  margin-top: 3vh;
  background-color: #bada55;
  display: flex;
  align-items: center;
  transition: 0.5s;
  overflow: hidden;
}
</style>

<script>
import Sidebar from "@/components/Sidebar";
import MainContent from "@/components/MainContent";

export default {
  components: {MainContent, Sidebar}
}
</script>