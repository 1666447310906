<template>
  <div class="padding">
    <h1>Lieferung {{ $route.params.id ?? "" }} | JPC-1234</h1>

    <div class="content-group">
      <div class="headline-group">
        <h2>Kunde</h2>
        <p>Leon Steinert</p>
      </div>
    </div>

    <div class="content-group">
      <div class="headline-group">
        <h2>Aktueller Status: Wartet auf Verpackung</h2>
        <p>Zenfulfillment: wartet auf Verpackung</p>
      </div>
      <div class="flex-align-center">
        <div class="status-diagram">
          <div class="circle finished registered"></div>
          <div class="circle sendToZF">
            <div class="circle-icon"></div>
          </div>
          <div class="circle shipped"></div>
          <div class="connection-line"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Details',
  mounted() {

  }
}
</script>

<style scoped>
h1 {
  color: white;
}

.padding {
  width: 74vw;
  height: calc(100vh - 6vw);
}

.flex-align-center {
  width: 74vw;
  display: flex;
  justify-content: center;
  margin-bottom: 2vh;
}

.status-diagram {
  width: 40vw;
  height: 4vh;
  display: flex;
  justify-content: space-between;
}

.connection-line {
  position: absolute;
  width: 40vw;
  height: 3vh;
  border-bottom: 3px solid #484e54;
  z-index: 1;
}

.circle {
  background-color: #484e54;
  height: 60px;
  width: 60px;
  position: relative;
  z-index: 2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-description {
  position: absolute;
  margin-top: 50px!important;
}

.finished {
  background-color: #bada55;
}
</style>
