import { createRouter, createWebHistory } from 'vue-router'
import Sent from '../views/Sent.vue'
import Status from "@/views/Status";
import Shipped from "@/views/Shipped";
import Returns from "@/views/Returns";
import Settings from "@/views/Settings";
import Actions from "@/views/Actions";
import Login from "@/views/Login";
import Details from '@/views/Details';
import Stocks from "@/views/Stocks";
import Registered from "@/views/Registered";
import Failed from "@/views/Failed";
import Retry from "@/views/Retry";

const routes = [
  {
    path: '/',
    name: 'Status',
    component: Status
  },
  {
    path: '/registriert',
    name: 'Registered',
    component: Registered
  },
  {
    path: '/uebertragen',
    name: 'Sent',
    component: Sent
  },
  {
    path: '/versandt',
    name: 'Shipped',
    component: Shipped
  },
  {
    path: '/retouren',
    name: 'Returns',
    component: Returns
  },
  {
    path: '/fehlgeschlagen',
    name: 'Failed',
    component: Failed
  },
  {
    path: '/wiederholen',
    name: 'Retry',
    component: Retry
  },
  {
    path: '/einstellungen',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/aktionen',
    name: 'Actions',
    component: Actions
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/details/:id',
    name: 'Details',
    component: Details
  },
  {
    path: '/bestaende',
    name: 'Bestaende',
    component: Stocks
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
