<template>
  <div class="padding">
    <h1>Registrierte Bestellungen</h1>
    <Table endpoint="/registered"></Table>
  </div>
</template>

<script>
import Table from "@/components/Table";

export default {
  name: 'Sent',
  components: {Table}
}
</script>

<style scoped>
h1 {
  color: white;
}

.padding {
  width: 74vw;
  height: calc(100vh - 6vw);
}
</style>
